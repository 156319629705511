import React, { Component } from 'react';
import './contact.css';
import { Link } from 'gatsby';
import Tanuja from './assets/images/Tanuja.jpg';
import Navbar from '../components/Navbar/Navbar';
import sds from '../../static/sds.png';
import Footer from '../components/Footer/Footer';
import Bg from '../components/parallax';

class Contact extends Component {
  render() {
    return (
      <>
        <Navbar />
        <Bg />
        <div className="main-content">
          <div className="con">
            <div className="contact">
              <div className="row">
                <div className="twelve columns">
                  <div className="move-to-sds-portal">
                    <Link to="/">
                      <img className="contact-img" src={sds} alt="SDS"></img>
                    </Link>
                    <h1 className="info-head">
                      Shape Your Project Idea To Reality With SDS
                    </h1>
                    <span className="main-btn">
                      <a href="https://portal.sdscoep.club/">
                        Move to SDS Portal
                      </a>
                    </span>
                  </div>
                  <h2
                    className="f-card-header"
                    style={{ color: '#fff', marginBottom: '20px' }}
                  >
                    Our Faculty Advisor
                  </h2>
                  <div className="faculty-advisor-main">
                    <div1 className="faculty-advisor-card">
                      <div className="f-card-image">
                        <img src={Tanuja} />
                      </div>
                      <div className="f-card-details">
                        <div className="f-card-title">
                          <h3>Tanuja Pattanshetti</h3>
                        </div>
                        <div className="f-card-description">
                          <h4>Faculty Advisor, SDS</h4>
                          <h4>Assistant Professor of </h4>
                          <h4>Dept. of Computer Science & Engineering,</h4>
                          <h4>COEP Technological University, Pune</h4>
                          <h4>
                            <a
                              id="faculty-mail"
                              href="mailto:trp.comp@coep.ac.in"
                            >
                              trp.comp@coep.ac.in
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div1>
                  </div>
                </div>
              </div>
            </div>
            <div className="map">
              <div className="location">
                <p>Location</p>
                <iframe
                  title="location"
                  style={{
                    width: '85%',
                    height: '400px',
                    frameborder: '0',
                    scrolling: 'no',
                    marginheight: '0',
                    marginwidth: '0',
                  }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8997.503846225845!2d73.84894526160814!3d18.52960865539658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c0883858b873%3A0x1d68fbf2cac75519!2sCollege%20Of%20Engineering%20Pune!5e0!3m2!1sen!2sin!4v1600751175579!5m2!1sen!2sin"
                ></iframe>
              </div>
            </div>
            <div className="home__footer">
              <Footer />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Contact;
